 

export default ({ list}) => {
  
 
 
  return (
    <div>
      {" "}
     
       
      <hr />
      <p className="font-semibold text-sm text-primary  my-4">
        All HQS   
         {list.length}
      </p>
      {list.map((ik) => (
        <LIEItem item={ik} />
      ))}
    </div>
  );
};

const LIEItem = ({ item }) => (
  <div className=" bg-gray-200 p-3 my-1 rounded-lg  relative  text-gray-600 hover:bg-gray-300 cursor-pointer">
    <p className="font-semibold">
      {" "}
      {item.firstname} {item.lastname}{" "}
    </p>
    <p className="font-[400]">{item.email}</p>
  </div>
);
