import { useState } from "react";
import { LGAs } from "../../constants/data";
import { useEffect } from "react";

export default ({list , asignedschhols, notasignedschhols, allschools}) => {
  const [activeLGa, setactive] = useState(LGAs[0]);

const [stafflist, setstafflist] =useState([])



useEffect(()=>{

   var newL=  list.filter((tut)=> tut.lgas .toLowerCase().split(",").includes(activeLGa.toLowerCase()))
setstafflist(newL )
console.log(newL)
},[list,  activeLGa])

  const FilterItem = ({ item, click }) => (
    <div
      onClick={() => {
        setactive(item);
      }}
      className={`${
        activeLGa == item ? "bg-black/80 font-semibold  text-white" : "  "
      } cursor-pointer rounded  border border-gray-600  font-light min-w-[200px] text-sm p-2 flex justify-center items-center  mx-3`}
    >
      {item}
    </div>
  );
  return (
    <div>
      <p className="font-semibold text-sm text-primary my-3 ">
        All LGAs {LGAs.length}
      </p>
      <div className=" flex w-[1000px] pt-10 overflow-x-scroll fade relative bg-gray-300 p-3 rounded-lg ">
        <small className="absolute top-3 left-3 text-primary">
          Select any LGA
        </small>
        {LGAs.map((item, index) => (
          <FilterItem item={item} key={index} />
        ))}
      </div>
      <hr />
      <ul className="flex list-disc w-full p-3 gap-4  justify-between items-center">
        <li className="text-sm">
          Total number of schools in LGA 
          <span className="font-bold text-lg  text-gray-500 ml-2">{allschools.filter((kl)=>kl.address?.toLowerCase() .includes(`${" "}${activeLGa.toLowerCase()}${" "}`)).length}</span>
        </li>
        <li>
          Total number of school evaluated
          <span className="font-bold text-lg  text-gray-500 ml-2">{asignedschhols.filter((kl)=>kl.address?.toLowerCase() .includes(`${" "}${activeLGa.toLowerCase()}${" "}`)).length}</span>
        </li>
        <li>
          Total number of school unevaluated
          <span className="font-bold text-lg  text-gray-500 ml-2 ">{ allschools.filter((kl)=>kl.address?.toLowerCase() .includes(`${" "}${activeLGa.toLowerCase()}${" "}`)).length - asignedschhols.filter((kl)=>kl.address?.toLowerCase() .includes(`${" "}${activeLGa.toLowerCase()}${" "}`)).length}</span>
        </li>
      </ul>
      <hr />
      <p className="font-semibold text-sm text-primary my-4 ">
        All LIEs in this LGA ({stafflist.length})
      </p>
      {stafflist.map((ik) => (
        <LIEItem asignedschhols={asignedschhols} item={ik} />
      ))}
    </div>
  );
};

const LIEItem = ({ item ,asignedschhols }) =>{
    


const count=asignedschhols.filter((kk)=>kk.reviewer.toLowerCase().includes(`${item.firstname.toLowerCase()} ${item.lastname.toLowerCase()}`)).length
    return(
  <div className=" bg-gray-200 p-3 my-1 rounded-lg  relative  text-gray-600 hover:bg-gray-300 cursor-pointer">
     <div>
      <p className="font-semibold">
        {item.firstname} {item.lastname} 
      </p>
      <p className="font-[400]">{item.email}</p>
      <p className="text-sm absolute right-5 font-semibold font-serif top-5">
        Evaluations: <span>{count}</span>
      </p>
    </div>
  </div>
);}
