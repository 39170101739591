import { useState } from "react";
import { LGAs, zones } from "../../constants/data";
import { useEffect } from "react";

export default ({ list, asignedschhols, notasignedschhols, allschools }) => {
  const [activeLGa, setactive] = useState(zones.map((ik) => ik.name)[0]);
  const [stafflist, setstafflist] = useState([]);

  const FilterItem = ({ item, click }) => (
    <div
      onClick={() => {
        setactive(item);
      }}
      className={`${
        activeLGa == item ? "bg-black/80 font-semibold  text-white" : "  "
      } cursor-pointer rounded  border border-gray-600  font-light min-w-[200px] text-sm p-2 flex justify-center items-center  mx-3`}
    >
      {item}
    </div>
  );

  useEffect(() => {
    

    var newL = list.filter((tut) => tut.lgas.includes(activeLGa));
    setstafflist(newL);
    console.log(newL);
  }, [list, activeLGa]);
  return (
    <div>
      {" "}
      <p className="font-semibold text-sm text-primary  my-3">
        All Zones {zones.length}
      </p>
      <div className=" flex w-[1000px] py-6 overflow-x-scroll fade relative bg-gray-300 p-3 rounded-lg ">
        <small className="absolute top-1 left-3 text-primary">
          Select any zone
        </small>
        {zones
          .map((ik) => ik.name)
          .map((item, index) => (
            <FilterItem item={item} key={index} />
          ))}
      </div>
      <hr />
      <ul className="flex list-disc w-full p-3 gap-4  justify-between items-center">
        <li className="text-sm">
          Total number of schools in Zone
          <span className="font-bold text-lg  text-gray-500 ml-2">
            {
              allschools.filter((kl) =>
                zones
                  .filter((lc) => lc.name == activeLGa)[0]
                  .lgas.some((ll) =>
                    kl.address
                      ?.toLowerCase()
                      .includes(`${" "}${ll.toLowerCase()}${" "}`)
                  )
              ).length
            }
          </span>
        </li>
        <li>
          Total number of school evaluated
          <span className="font-bold text-lg  text-gray-500 ml-2">
            {
              asignedschhols.filter((kl) =>
                zones
                  .filter((lc) => lc.name == activeLGa)[0]
                  .lgas.some((ll) =>
                    kl.address
                      ?.toLowerCase()
                      .includes(`${" "}${ll.toLowerCase()}${" "}`)
                  )
              ).length
            }
          </span>
        </li>
        <li>
          Total number of school unevaluated
          <span className="font-bold text-lg  text-gray-500 ml-2 ">
            {
              notasignedschhols.filter((kl) =>
                zones
                  .filter((ll) => ll.name == activeLGa)[0]
                  .lgas.some((ll) =>
                    kl.address
                      ?.toLowerCase()
                      .includes(`${" "}${ll.toLowerCase()}${" "}`)
                  )
              ).length
            }
          </span>
        </li>
      </ul>
      <hr />
      <p className="font-semibold text-sm text-primary  my-4">
        All ZEs in {activeLGa} (
        {zones.filter((ll) => ll.name == activeLGa)[0].lgas.join(", ")}){" "}
        {stafflist.length}
      </p>
      {stafflist.map((ik) => (
        <LIEItem item={ik} />
      ))}
    </div>
  );
};

const LIEItem = ({ item }) => (
  <div className=" bg-gray-200 p-3 my-1 rounded-lg  relative  text-gray-600 hover:bg-gray-300 cursor-pointer">
    <p className="font-semibold">
      {" "}
      {item.firstname} {item.lastname}{" "}
    </p>
    <p className="font-[400]">{item.email}</p>
  </div>
);
