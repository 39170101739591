import { MdDashboard } from "react-icons/md";
 
import { FaCertificate, FaComment, FaUser } from "react-icons/fa";
 
import { BiMessage, BiSolidInbox, BiSolidSchool } from "react-icons/bi";
 
import { HiOutlineDocumentReport } from "react-icons/hi";




const itemstyle = { size: 15, color: "#fff" };
const categories = [
  { name: "Nusery", id: "nusery" },

  { name: "Primary", id: "pprimary" },
  { name: "Secondary", id: "secondary" },
];
const roles = [
  {
    name: "Principal",
    roleid: "Principal",
  },
  {
    name: "Teacher",
    roleid: "Teacher",
  },
  {
    name: "Proprietor",
    roleid: "Proprietor",
  },
  {
    name: "Headmaster/Headmistress",
    roleid: "Headmaster/Headmistress",
  },
];
const omited_reviewitem =[ {
  ItemName: "Leadership & Management",
  totalScore: 20,
  updatedAt: "",
  status:"nill",
  score: 0,
  questions: [
    { title: "Availability of Principal's office", type: "single", score: 2 },
    { title: "Availability of Vice Principal's office", type: "single", score: 1 },
    {
      title: "Availability of adequate staff room",
      type: "single",
      score: 1,
    },
    {
      title: "Availability of their different functions and duties printed and pasted (i.e the Principal, V.P & Staff members)",
      type: "single",
      score: 1,
    },
    {
      title: "Availability of qualified teachers in all subjects",
      type: "single",
      score: 1,
    },
    {
      title: "Availability of qualified teachers in all subjects",
      type: "single",
      score: 2,
    },
    {
      title: "Availability of school hall",
      type: "single",
      score: 1,
    },
    {
      title: "Availability of fixed chairs and tables in the hall",
      type: "single",
      score: 1,
    },
    {
      title: "Well-equipped Physics Laboratory",
      type: "single",
      score: 1,
    },
    {
      title: "Well-equipped Chemistry Laboratory",
      type: "single",
      score: 1,
    },
    {
      title: "Well-equipped Biology/Agricultural Laboratory",
      type: "single",
      score: 1,
    },
    {
      title: "Well-equipped Computer room/ICT",
      type: "single",
      score: 1,
    },
    {
      title: "Availability of Student Representative Council",
      type: "single",
      score: 1,
    },
    {
      title: "Records of decisions taking with teachers in charge",
      type: "single",
      score: 1,
    },
    {
      title: "Suggestion box to the management",
      type: "single",
      score: 1,
    },
    {
      title: "Availability of examination questions (Exam Banks)",
      type: "single",
      score: 1,
    },
    {
      title: "Availability of exam records Reports sheet/broad sheets",
      type: "single",
      score: 1,
    },
    {
      title: "Availability of well stocked library with relevant and approved texts",
      type: "single",
      score: 1,
    },
  ],
}]
const States = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];
const LGAs = [
  "Atakumosa West",
  "Atakumosa East",
  "Ayedaade",
  "Ayedire",
  "Boluwaduro",
  "Boripe",
  "Ede South",
  "Ede North",
  "Egbedore",
  "Ejigbo",
  "Ife North",
  "Ife South",
  "Ife-Central",
  "Ife-East",
  "Ifelodun",
  "Ila",
  "Ilesa-East",
  "Ilesa-West",
  "Ifedayo",
  "Ife-East area office",
  "Irepodun",
  "Irewole",
  "Isokan",
  "Iwo",
  "Obokun",
  "Odo-Otin",
  "Ola Oluwa",
  "Olorunda",
  "Oriade",
  "Orolu",
  "Osogbo",
];

const schoolfacilitycategory = [
  { name: "Classroom", type: "classroom" },

  { name: "Playground", type: "playground" },
  { name: "Sport Facility", type: "sports" },
  { name: "Labouratory", type: "lab" },
  { name: "Mosque", type: "mosque" },
  { name: "Chapel", type: "chapel" },
  { name: "ICT", type: "ict" },
  { name: "Boarding Facility", type: "boarding" },
];



const paymentItems =[{

  title:"Quality Assurance Assessment Fee",
  dueDate:new Date().toDateString(),
  nextDueDate:new Date().toDateString(),

}]


const zones = [
  {
    name: "IWO",
    lgas: ["IREWOLE", "OLA-OLUWA", "ISOKAN", "AYEDIRE", "IWO", "AYEDAADE"],
  },
  {
    name: "OSOGBO",
    lgas: ["OSOGBO", "OROLU", "OLORUNDA", "IREPODUN"],
  },

  {
    name: "IFE",
    lgas: ["IFE CENTRAL", "IFE EAST", "IFE EAST AREA OFFICE", "IFE SOUTH","IFE NORTH"],
  },
  {
    name: "EDE",
    lgas: ["EDE NORTH", "EDE SOUTH", "EJIGBO", "EGBEDORE"],
  },
  {
    name: "IKIRUN",
    lgas: ["IFELODUN", "IFEDAYO", "BORIPE", "ODO-OTIN","BOLUWADURO","ILA"],
  },
  {
    name: "ILESA",
    lgas: ["ATAKUMOSA EAST", "ATAKUMOSA WEST", "OBOKUN", "ORIADE","ILESA EAST","ILESA WEST"],
  },
];
const schooladminitems = [
  {title:'Certificates', path:'/dashboard/certificates'}
,   {title:'Fees', path:'/dashboard/fees'},
{title:'Facilities', path:'/dashboard/facilities'},
{title:'Payments', path:'/dashboard/payments'},
{title:'Map location', path:'/dashboard/mapadmin'}
];

const sidebaritems = [
  {
    name: "Profile",
    path: "/dashboard",
    icon: (
      <MdDashboard
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },

  {
    name: "All Schools",
    path: "/dashboard/allschools",
    icon: (
      <BiSolidSchool 

        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
  {
    name: "Staff",
    path: "/dashboard/staffs",
    icon: (
      <FaUser 
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),},
  
  {
    name: "Govt. Update",
    path: "/dashboard/messages",
    icon: (
      <BiMessage 

        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
  {
    name: "Evaluations",
    path: "/dashboard/evaluations",
    icon: (
      <HiOutlineDocumentReport 

        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
 
 
  // {
  //   name: "Ongoing Review",
  //   path: "/dashboard/ongoing",
  //   icon: (
  //     <FaSchoolCircleExclamation
  //       className="inline"
  //       size={itemstyle.size}
  //       color={itemstyle.color}
  //     />
  //   ),
  // },
  // {
  //   name: "Reviewed by me",
  //   path: "/dashboard/reviewed",
  //    icon: (
  //     <FaSchoolCircleCheck
  //       className="inline"
  //       size={itemstyle.size}
  //       color={itemstyle.color}
  //     />
  //   ),
  // }
   
  
];

export {
  sidebaritems,
  States,
  LGAs,
  omited_reviewitem,
  roles,
  categories,
  paymentItems,
  zones,
  schooladminitems,
  schoolfacilitycategory,
};


  